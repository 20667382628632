import { formatLocationAndParams } from '../../location';
import { useData, getKpis, getMetrics } from '../../api';
import { transformMetricsPair } from '../../data/utils/transformMetricsPair';
import { useHandleNotGeneratedPeriod } from './useHandleNotGeneratedPeriod';

const pairErrors = (errorA, errorB, locationA, locationB, translate) => {
  let locationList = [];
  if (errorA) locationList.push(locationA);
  if (errorB) locationList.push(locationB);

  if (locationList.length === 0) {
    return null;
  }

  let locationNames = [...new Set(locationList)]
    .map(l => formatLocationAndParams(l, translate).location)
    .join(' and ');

  return {
    errorA,
    errorB,
    message: `${translate({ id: 'not_load_data' })} ${locationNames}.`,
  };
};

export default function useCompareItemsData(
  periodId,
  locationAndParamsA,
  locationAndParamsB,
  useUsdAmount,
  includeTaxes,
  useLLY,
  translate,
  trendsPeriods
) {
  const {
    data: kpisData,
    loading: kpisLoading,
    error: kpisError,
    refetch: kpisRefetch,
  } = useData(getKpis, {}, 'kpis');

  const responseA = useData(
    getMetrics,
    {
      period: periodId,
      location: locationAndParamsA.location.id,
      ...locationAndParamsA.params,
    },
    'metricsA'
  );
  useHandleNotGeneratedPeriod({ response: responseA, status: 0 });
  const {
    data: dataA,
    loading: loadingA,
    error: errorA,
    refetch: refetchA,
  } = responseA;

  const responseB = useData(
    getMetrics,
    {
      period: periodId,
      location: locationAndParamsB.location.id,
      ...locationAndParamsB.params,
    },
    'metricsB'
  );
  useHandleNotGeneratedPeriod({ response: responseB, status: 0 });
  const {
    data: dataB,
    loading: loadingB,
    error: errorB,
    refetch: refetchB,
  } = responseB;

  const loading = loadingA || loadingB || kpisLoading;
  const error =
    kpisError ||
    pairErrors(
      errorA,
      errorB,
      locationAndParamsA,
      locationAndParamsB,
      translate
    );

  let data;
  if (!loading && !error) {
    data = transformMetricsPair(
      kpisData,
      dataA,
      dataB,
      useUsdAmount,
      includeTaxes,
      periodId,
      useLLY,
      trendsPeriods
    );
  }

  return {
    data,
    loading,
    error,
    refetch: () => {
      kpisRefetch();
      refetchA();
      refetchB();
    },
  };
}
