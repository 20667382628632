import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { usePreferencesManager } from '../user-preferences';

export const withTraslation = id => {
  return <FormattedMessage id={id} />;
};

export const LanguageProvider = ({ children, messages, locale }) => {
  const pm = usePreferencesManager();
  //const language = pm.get("preferredLanguage") || props.locale;
  const [language, setLanguage] = useState(
    pm?.get('preferredLanguage') || locale
  );

  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={messages[language]}
    >
      {children(setLanguage)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.func.isRequired,
};
