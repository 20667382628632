import { useMemo } from 'react';

import { useData, getLocations } from '../../../api';
import { index as indexArray } from '../../../util/array';
import { omit } from '../../../util/object';
import { arrayToTree } from '../../../util/tree';
import onlyUsefulLocations from './onlyUsefulLocations';

export default function useLocationData() {
  const { data, error, loading } = useData(getLocations);

  const locationData = useMemo(() => {
    if (!data) {
      return;
    }
    return data?.locations
      .filter(item => item.level === 'store')
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }, [data]);

  const locations = useMemo(() => {
    if (!data) {
      return;
    }

    const locationsById = indexArray(data.locations, 'id');
    const locations = data.locations.map(location => {
      if (!locationsById.has(location.parentId)) {
        // Object must be copied because useMemo is not a guarantee.
        return omit(location, 'parentId');
      }
      return location;
    });

    const root = arrayToTree(locations);
    const prunedRoot = onlyUsefulLocations(root);
    return prunedRoot;
  }, [data]);

  return { data: locations, locations: locationData, loading, error };
}
