import React from 'react';
import { Tab, Tabs, withStyles, Paper } from '@material-ui/core';

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minHeight: 'unset',
    padding: '1px 0',
    ...theme.typography.body1,
  },
}))(Tab);

const ButtonTab = props => <StyledTab disableRipple {...props} />;
ButtonTab.propTypes = { ...Tab.propTypes };

const StyledPaper = withStyles({
  root: {
    flex: 1,
    margin: 2,
  },
})(Paper);

const StyledTabs = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.panel,
    borderRadius: 6,
    minHeight: 'unset',
  },
  flexContainer: {
    position: 'relative',
    zIndex: 1,
  },
  indicator: {
    background: 'none',
    display: 'flex',
    height: '100%',
  },
}))(Tabs);

const ButtonTabs = ({ variant = 'fullWidth', ...props }) => (
  <StyledTabs
    variant={variant}
    {...props}
    TabIndicatorProps={{
      children: <StyledPaper elevation={1} />,
    }}
  />
);
// eslint-disable-next-line react/forbid-foreign-prop-types
ButtonTabs.propTypes = { ...Tabs.propTypes };

export { ButtonTabs, ButtonTab };
