//import { params } from './data';

export function formatChannel(value, translate) {
  //const formatted = params.channel.byValue.get(value)?.label;
  const formatted = translate(value);
  if (!formatted) {
    throw new Error(
      `Unable to format channel. Unknown channel value: ${JSON.stringify(
        value
      )}`
    );
  }
  return formatted;
}

export function formatBusinessModel(value, translate) {
  //const formatted = params.businessModel.byValue.get(value)?.label;
  const formatted = translate(value);
  if (!formatted) {
    throw new Error(
      `Unable to format business model. Unknown business model value: ${JSON.stringify(
        value
      )}`
    );
  }
  return formatted;
}

export function formatParams(params, translate) {
  const { channel, businessModel } = params || {};
  let formattedParts = [];
  if (channel) {
    formattedParts.push(formatChannel(channel, translate));
  }
  if (businessModel) {
    formattedParts.push(formatBusinessModel(businessModel, translate));
  }
  let paramStr = translate('allStores');
  if (formattedParts.length > 0) {
    paramStr = formattedParts.join(' / ');
  }
  if (formattedParts.length === 1) {
    paramStr += ' only';
  }
  return paramStr;
}

export function formatLocationAndParams(locationAndParams, translate) {
  const isStore = locationAndParams?.location?.level === 'store';

  return {
    location: locationAndParams?.location?.name,
    params: isStore
      ? undefined
      : formatParams(locationAndParams?.params, translate),
  };
}
