import PropTypes from 'prop-types';
import React from 'react';
import { CardGrid, PromotedMetricCard, AddKPICard } from '../components/Cards';
import Header from '../components/Header';
import RealTimeKPIList from '../components/RealTimeKPIList';
import LoadingBar from '../components/LoadingBar';
import SectionSpacer from '../components/SectionSpacer';
import TimeStampPanel from '../components/TimeStampPanel';
import MetricsShell from '../data/components/MetricsShell';
import SortShell from '../data/components/SortShell';
import theme from '../theme';
import useRealTimeMetricsData from '../data/hooks/useRealTimeMetricsData';
import { LocationAndParamsShape } from '../location';
import { gtag, PageView } from '../gtag';
import { ErrorPage } from '../components/Errors';
import { isEmpty } from 'lodash';

const FavMetricCard = ({ onClick, metric }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick();
  };
  if (!metric) {
    return (
      <AddKPICard
        onClick={handleClick}
        bgColor="primary"
        pluscolor="tertiary"
      />
    );
  }
  return (
    <PromotedMetricCard
      // onClick={handleClick}
      key={metric.id}
      metric={metric}
      color="tertiary"
      spacing={0.5}
    />
  );
};

const SentimentCards = ({ data, favMetric, onFavMetricClick }) => {
  return (
    data && (
      <>
        <CardGrid itemXs={6} itemLg={3} justify="flex-start">
          {data.map(m => (
            <PromotedMetricCard
              key={m.id}
              metric={m}
              color="tertiary"
              spacing={0.5}
            />
          ))}
          <FavMetricCard metric={favMetric} onClick={onFavMetricClick} />
        </CardGrid>
      </>
    )
  );
};

const TodayCore = ({
  promotedKpis,
  favMetric,
  onSelectSort,
  onSelectFav,
  otherKpis,
}) => (
  <>
    <Header
      title="Main KPIS"
      icon
      onIconClick={onSelectFav}
      style={{ rootColor: '#fff', sortColor: '#fff' }}
    />
    <SentimentCards
      data={promotedKpis}
      favMetric={favMetric}
      onFavMetricClick={onSelectFav}
    />
    <SectionSpacer />
    <Header
      title="Other KPIS"
      icon
      onIconClick={onSelectSort}
      style={{ rootColor: '#fff', sortColor: '#fff' }}
    />
    <RealTimeKPIList metrics={otherKpis} />
  </>
);

const TodayDataContainer = ({
  period,
  location: locationAndParams,
  userSettings,
}) => {
  const { useUsdAmount, includeTaxes } = userSettings;
  const { location, params: locationParams } = locationAndParams;
  const locationId = location.id;
  const { data, loading, error, refetch } = useRealTimeMetricsData(
    locationId,
    locationParams,
    period.id,
    useUsdAmount,
    includeTaxes
  );
  if (loading) {
    return <LoadingBar />;
  }
  if (error || isEmpty(data)) {
    return (
      <ErrorPage message="Could not load Today data." onClickRetry={refetch} />
    );
  }

  return (
    <>
      {data.formattedTimeStamp && (
        <TimeStampPanel
          formattedValue={data.formattedTimeStamp}
          text={`Data updated on ${data.formattedTimeStamp}`}
          style={{
            background: 'rgba(255, 255, 255, 0.12)',
            color: 'rgba(255, 255, 255, 0.65)',
            fontWeight: 'normal',
            textTransform: 'initial',
            fontSize: '1.2rem',
            marginTop: theme.spacing(-2),
            marginBottom: theme.spacing(1.5),
          }}
        />
      )}
      <SortShell
        sortOptionsId={['name', 'default']}
        onSort={({ id }) => {
          gtag('event', 'sort', { event_category: 'Today', event_label: id });
        }}
      >
        {(sort, toggleSortDrawer) => {
          const promotedKpis = data.filter(x => x.promoted);
          const otherKpis = data.filter(x => !x.promoted).sort(sort.func);
          return (
            <MetricsShell metrics={otherKpis}>
              {(favMetric, toggleFavSelect) => (
                <PageView
                  params={{
                    period_id: period.id,
                    location_id: location.name,
                    channel: locationParams.channel,
                  }}
                >
                  <TodayCore
                    promotedKpis={promotedKpis}
                    favMetric={favMetric}
                    otherKpis={otherKpis.filter(x => x !== favMetric)}
                    onSelectSort={toggleSortDrawer}
                    onSelectFav={toggleFavSelect}
                  />
                </PageView>
              )}
            </MetricsShell>
          );
        }}
      </SortShell>
    </>
  );
};

TodayDataContainer.propTypes = {
  period: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  location: LocationAndParamsShape.isRequired,
  userSettings: PropTypes.shape({
    useUsdAmount: PropTypes.bool.isRequired,
    includeTaxes: PropTypes.bool.isRequired,
  }),
};

const Today = React.memo(
  TodayDataContainer,
  (prevProps, nextProps) =>
    prevProps.location === nextProps.location &&
    prevProps.period === nextProps.period &&
    prevProps.userSettings === nextProps.userSettings
);

export default Today;
