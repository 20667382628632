import React, { createContext, useContext, useState, useMemo } from 'react';

const RetryPeriod = createContext({
  retryPeriod: false,
  setRetryPeriod: () => {},
});

export const RetryPeriodProvider = ({ children }) => {
  const [retryPeriod, setRetryPeriod] = useState(false);

  const value = useMemo(
    () => ({
      retryPeriod,
      setRetryPeriod,
    }),
    [retryPeriod, setRetryPeriod]
  );

  return <RetryPeriod.Provider value={value}>{children}</RetryPeriod.Provider>;
};

export const useRetryPeriod = () => useContext(RetryPeriod);
