import { useData, getKpis, getMetrics } from '../../api';
import defaultSort from '../utils/defaultSort';
import { transformMetrics } from '../utils/transforms';
import { useHandleNotGeneratedPeriod } from './useHandleNotGeneratedPeriod';

export default function useMetricsData(
  locationId,
  locationParams,
  periodId,
  useUsdAmount,
  includeTaxes,
  useLLY,
  trendsPeriods
) {
  const kpis = useData(getKpis, {}, 'kpis');
  const metrics = useData(
    getMetrics,
    {
      period: periodId,
      location: locationId,
      ...locationParams,
    },
    'metrics'
  );
  let data;
  if (!kpis.loading && !metrics.loading) {
    if (metrics.data && kpis.data) {
      const salesMetrics = metrics.data?.metrics?.total_sales || {};
      data = transformMetrics({
        metricsResponse: { metrics: salesMetrics },
        kpiMetaResponse: kpis.data,
        useUsdAmount,
        includeTaxes,
        useLLY,
        periodId,
        trendsPeriods,
      });
      data.sort(defaultSort);
    }
  }

  useHandleNotGeneratedPeriod({ response: metrics, status: 0 });

  return {
    data,
    loading: kpis.loading || metrics.loading,
    error: kpis.error || metrics.error,
    refetch: () => {
      if (kpis.error) kpis.refetch();
      if (metrics.error) metrics.refetch();
    },
  };
}
