const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line

const enTranslationMessages = require('./translations/en.json');
const msTranslationMessages = require('./translations/ms.json');
const jaTranslationMessages = require('./translations/ja.json');
const zhCNTranslationMessages = require('./translations/zhcn.json'); // simplified
// const zhTWTranslationMessages = require('./translations/zhtw.json'); // traditional

export const DEFAULT_LOCALE = 'en';

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  ms: formatTranslationMessages('ms', msTranslationMessages),
  ja: formatTranslationMessages('ja', jaTranslationMessages),
  'zh-CN': formatTranslationMessages('zhcn', zhCNTranslationMessages),
  // 'zh-TW': formatTranslationMessages('zhtw', zhTWTranslationMessages),
};
