import { transformProducts_v2 } from '../utils/productsTransforms_v2';
import { useData, getProductData } from '../../api';

export function useNoPerformance(...args) {
  const response = useData(null, ...args);
  const { data, loading, error, refetch } = response;
  return {
    data,
    error,
    loading,
    refetch,
  };
}

export function usePerformanceData(
  locationId,
  locationParams,
  periodId,
  useUsdAmount,
  includeTaxes
) {
  const response = useData(getProductData, {
    period: periodId,
    location: locationId,
    ...locationParams,
  });
  // useHandleNotGeneratedPeriod(response);
  const { data, loading, error, refetch } = response;

  let products;
  if (data) {
    products = transformProducts_v2(
      data['products'],
      useUsdAmount,
      includeTaxes
    );
  }

  return {
    data: products,
    error,
    loading,
    refetch,
  };
}
