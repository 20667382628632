import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { LanguageProvider } from '../data/LanguageProvider';
import { translationMessages } from '../localization/i18n';

import { SecureRoute } from '../auth';
import LocationsShell from '../data/components/LocationsShell';
import PeriodsShell from '../data/components/PeriodsShell';
import UserShell from '../data/components/UserShell';
import { formatLocationAndParams } from '../location';
import ErrorBoundary from '../ErrorBoundary';
import { Comparison, Login, KPIs, Products } from '../pages';
import { useRetryPeriod } from '../data/hooks/useRetryPeriod';
import { useUpdateManager } from '../serviceWorker';
import { AppBar } from './AppBar';
import BottomBar from './BottomBar';
import { ErrorPageOrChildren } from './Errors';
import { usePreferencesManager } from '../data/user-preferences';
import { getPeriods } from '../api';
import { useSecurity } from '../auth';
import { useConfig } from '../config';
import { clearLocalStorageApiCache } from '../api/localstorage';

const useStyles = props => {
  return makeStyles(
    theme => ({
      contentArea: {
        paddingLeft: `calc(${theme.spacing(
          1.5
        )}px + env(safe-area-inset-left))`,
        paddingRight: `calc(${theme.spacing(
          1.5
        )}px + env(safe-area-inset-right))`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      app: {
        minHeight: '100vh',
        background: props.showPeriodsBar
          ? theme.palette.background.default
          : theme.palette.primary.main,
        color: theme.palette.text.primary,
      },
    }),
    { name: 'LriApp' }
  );
};
const RoutedApp = props => {
  useEffect(() => {
    clearLocalStorageApiCache();
  }, []);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <App {...props} />
    </Router>
  );
};

const checkPeriodsData = (baseUrl, fetchOptions, refreshedDate, history) => {
  const result = getPeriods(baseUrl, {}, fetchOptions)
    .then(responseData => {
      if (responseData?.current_date !== refreshedDate) {
        history.go(0);
      }
    })
    .catch(error => console.warn(error));
  return result;
};

const App = () => {
  const showTimeStamp = false; // using this flag to hide timestamp panel for temporary in all screens
  const history = useHistory();
  const location = useLocation();
  const pm = usePreferencesManager();
  const { setRetryPeriod } = useRetryPeriod();
  const { checkForUpdates } = useUpdateManager();
  const {
    API_BASE_URL: baseUrl,
    DATA_REFRESH_START_HOUR: refreshHourStart,
    DATA_REFRESH_END_HOUR: refreshHourEnd,
  } = useConfig();
  const { accessToken } = useSecurity();
  const fetchOptions = accessToken
    ? { headers: { Authorization: `Bearer ${accessToken}` } }
    : {};
  const handleBottomBarChange = (e, value) => {
    history.push(`/${value}`);
    setRetryPeriod(false);
    checkForUpdates();
  };

  const handleClickRetry = () => {
    history.go(0);
  };

  setInterval(() => {
    var presentTime = new Date().getTime();
    // var presentDate = new Date().getDate();
    const dataRefreshDate = JSON.parse(pm.get('data_refresh_date') || '{}');
    var refreshTime = dataRefreshDate?.period_updated;
    const refreshedDate = dataRefreshDate?.current_date;
    const timeDiff = (presentTime - refreshTime) / 60000;
    // const dateDiff = (presentDate - refreshedDate);
    // new Date(dataRefreshDate?.current_date).getDate();

    let getCurrentHour = new Date().getUTCHours();

    // offsetting next day time with 24 hours
    if (getCurrentHour <= refreshHourEnd) getCurrentHour += 24;

    if (
      getCurrentHour >= refreshHourStart &&
      getCurrentHour <= refreshHourEnd + 24 &&
      timeDiff > 30
    ) {
      checkPeriodsData(baseUrl, fetchOptions, refreshedDate, history);
    }
  }, 900000); // 15 * 60 * 1000

  const showPeriodsBar = location.pathname !== '/today'; // checking for today tab

  const styles = useStyles({ showPeriodsBar })();

  return (
    <div className={styles.app}>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <SecureRoute path="/">
          <LanguageProvider locale="en" messages={translationMessages}>
            {setLanguage => (
              <LocationsShell onClose={checkForUpdates}>
                {(
                  getLocationAndParams,
                  toggleLocationSelect,
                  locationError,
                  translateString
                ) => {
                  const locationAndParams = getLocationAndParams('global');
                  let { location: formattedLocation, params: formattedParams } =
                    formatLocationAndParams(locationAndParams, translateString);

                  return (
                    <PeriodsShell onClose={checkForUpdates}>
                      {(
                        period, // selected fixedPeriodOptions
                        togglePeriodSelect,
                        getSelectedPeriod,
                        getTodaysPeriod,
                        periodsError
                      ) => (
                        <UserShell
                          onClose={checkForUpdates}
                          setLanguage={setLanguage}
                        >
                          {(settings, toggleSideMenu) => (
                            <>
                              <Switch>
                                <Route path="/compare">
                                  <AppBar
                                    mainTitle={translateString('Compare')}
                                    secondaryTitle={period}
                                    onPeriodChangeClick={togglePeriodSelect}
                                    onAvatarClick={toggleSideMenu}
                                    period={getSelectedPeriod()}
                                  />
                                  <Container className={styles.contentArea}>
                                    <ErrorPageOrChildren
                                      onClickRetry={handleClickRetry}
                                      errors={[locationError, periodsError]}
                                    >
                                      <ErrorBoundary>
                                        <Comparison
                                          period={getSelectedPeriod()}
                                          locationA={getLocationAndParams(
                                            'storeA'
                                          )}
                                          locationAOnClick={toggleLocationSelect(
                                            'storeA'
                                          )}
                                          locationB={getLocationAndParams(
                                            'storeB'
                                          )}
                                          locationBOnClick={toggleLocationSelect(
                                            'storeB'
                                          )}
                                          userSettings={settings}
                                          showTimeStamp={showTimeStamp}
                                          translate={translateString}
                                        />
                                      </ErrorBoundary>
                                    </ErrorPageOrChildren>
                                  </Container>
                                </Route>
                                <Route path="/">
                                  <AppBar
                                    toggleLocationSelect={toggleLocationSelect(
                                      'global'
                                    )}
                                    mainTitle={formattedLocation}
                                    subtitle={formattedParams}
                                    secondaryTitle={period}
                                    onPeriodChangeClick={togglePeriodSelect}
                                    onAvatarClick={toggleSideMenu}
                                    showPeriodsBar={showPeriodsBar}
                                    period={getSelectedPeriod()}
                                  />
                                  <Container className={styles.contentArea}>
                                    <ErrorPageOrChildren
                                      onClickRetry={handleClickRetry}
                                      errors={[locationError, periodsError]}
                                    >
                                      <Route exact path="/">
                                        <Redirect to="/kpis" />
                                      </Route>
                                      <Route path="/today">
                                        <Redirect to="/" />
                                      </Route>
                                      {/* <Route exact path="/">
                                    <ErrorBoundary>
                                      <Today
                                        period={getTodaysPeriod()}
                                        location={getLocationAndParams(
                                          'global'
                                        )}
                                        userSettings={settings}
                                        showTimeStamp={showTimeStamp}
                                      />
                                    </ErrorBoundary>
                                  </Route> */}
                                      <Route exact path="/kpis">
                                        <ErrorBoundary>
                                          <KPIs
                                            period={getSelectedPeriod()}
                                            location={getLocationAndParams(
                                              'global'
                                            )}
                                            userSettings={settings}
                                            showTimeStamp={showTimeStamp}
                                          />
                                        </ErrorBoundary>
                                      </Route>
                                      <Route path="/products">
                                        <ErrorBoundary>
                                          <Products
                                            period={getSelectedPeriod()}
                                            location={getLocationAndParams(
                                              'global'
                                            )}
                                            userSettings={settings}
                                            showTimeStamp={showTimeStamp}
                                          />
                                        </ErrorBoundary>
                                      </Route>
                                    </ErrorPageOrChildren>
                                  </Container>
                                </Route>
                              </Switch>
                              <BottomBar
                                value={location.pathname.substr(1)}
                                onChange={handleBottomBarChange}
                              />
                            </>
                          )}
                        </UserShell>
                      )}
                    </PeriodsShell>
                  );
                }}
              </LocationsShell>
            )}
          </LanguageProvider>
        </SecureRoute>
      </Switch>
    </div>
  );
};

export default RoutedApp;
