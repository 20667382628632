import { joinBy } from './joinBy';
import { renameProp, isNullOrUndefined } from '../../util/object';
import { computeDpy } from './computeDpy';
import { formatKpi, formatTimeStamp, formatTimeStamp1 } from '../../format';
import { semanticPeriodsWithValidation } from './periods';
import { selectCurrencyFromMetricsResponse } from './currencySelectors';
import { withTraslation } from '../LanguageProvider';

export const attachKpiMeta = (metrics, kpiMeta) => {
  return joinBy(metrics, kpiMeta, 'metric', 'meta', x => x.id)
    .filter(x => !!x.metric && !!x.meta)
    .map(x => ({ ...x.metric, ...x.meta }));
};

export const mapIds = renameProp('metricId', 'id');

export const idMapToArray = map =>
  Object.entries(map).map(([k, v]) => ({ metricId: k, ...v }));

export function createValueFromFormat(m) {
  if (isNullOrUndefined(m.value)) {
    return null;
  }
  if (m.format.startsWith('money')) {
    return [
      m.value,
      {
        currency: 'USD',
        amount: m.value.usdValue,
      },
    ];
  }
  return m.value;
}

export const normalizeFormatAndCurrency = ({
  id,
  promoted,
  order,
  category,
  description,
  label,
  realtime,
  ...m
}) => ({
  id,
  promoted,
  order,
  category,
  description,
  label,
  realtime,
  kpiValue: {
    format: m.format || 'number',
    compare: m.compare,
    currentLLYAmount: m.value_lly,
    currentAmount: createValueFromFormat(m),
    currentYearCompAmount: createValueFromFormat({
      format: m.format,
      value: m.value_comp,
    }),
    currentYearLlyAmount: createValueFromFormat({
      format: m.format,
      value: m.value_comp,
    }),
    targetAmount: createValueFromFormat({ format: m.format, value: m.target }),
  },
  trends: m.trends ? m.trends : {},
  hasTrends: m.hasTrends,
});

export function attachPrevPeriod(
  metrics,
  previousMetrics,
  currency,
  trendsPeriods
) {
  return joinBy(metrics, previousMetrics, 'metric', 'prevMetric', x => x.id)
    .filter(x => !!x.metric)
    .map(x => ({
      ...x.metric,
      kpiValue: {
        ...x.metric.kpiValue,
        prevYearAmount: x.prevMetric
          ? createValueFromFormat({
              ...x.prevMetric,
              format: x.metric.kpiValue.format,
            })
          : null,
        prevYearCompAmount: createValueFromFormat({
          format: x.metric.kpiValue.format,
          value: x.prevMetric?.value_comp,
        }),
      },
      formattedTrends:
        x.metric.hasTrends && x.prevMetric.trends
          ? transformTrendswithPY(
              x.metric.trends,
              x.prevMetric.trends,
              x.metric.kpiValue.format,
              currency,
              trendsPeriods
            )
          : {},
    }));
}

export function collapseAndAttachWithTaxMetric(metrics, taxPairs) {
  const taxMap = new Map(taxPairs);
  const inverseTaxMap = new Map(taxPairs.map(([k, v]) => [v, k]));
  const metricsMap = new Map(metrics.map(m => [m.id, m]));

  let all = metrics
    .filter(m => !inverseTaxMap.has(m.id))
    .map(m => ({
      ...m,
      taxMetric: taxMap.get(m.id) ? metricsMap.get(taxMap.get(m.id)) : null,
    }));

  return all;
}

export function standardizeMetricsResponse_v2(
  metricsResponse_v2,
  kpiMetaResponse,
  useLLY,
  periodId,
  currency,
  trendsPeriods
) {
  let { currentPeriod, previousPeriod } = {};

  const semanticPeriods = semanticPeriodsWithValidation(
    metricsResponse_v2.metrics,
    useLLY,
    periodId
  );
  if (!semanticPeriods) {
    return {
      metrics: [],
      isTherePrevPeriod: false,
      timeStamp: undefined,
    };
  } else {
    currentPeriod = semanticPeriods.currentPeriod;
    previousPeriod = semanticPeriods.previousPeriod;

    const currentMetrics = idMapToArray(
      metricsResponse_v2.metrics[currentPeriod]
    );
    let previousMetrics;
    if (previousPeriod && metricsResponse_v2.metrics[previousPeriod]) {
      previousMetrics = idMapToArray(
        metricsResponse_v2.metrics[previousPeriod]
      );
    }

    const metrics = standardizeMetricsResponseArrays(
      currentMetrics,
      previousMetrics,
      kpiMetaResponse,
      currency,
      trendsPeriods
    );

    return {
      metrics,
      isTherePrevPeriod: !!previousPeriod,
      timeStamp: currentMetrics.find(c => c.asOf)?.asOf,
    };
  }
}

export function standardizeMetricsResponseArrays(
  currentMetrics,
  previousMetrics,
  kpiMetaResponse,
  currency,
  trendsPeriods
) {
  const kpiMeta = kpiMetaResponse.kpis.map(mapIds);
  const taxPairs = kpiMetaResponse.taxpairs;

  let metrics;
  metrics = attachKpiMeta(currentMetrics.map(mapIds), kpiMeta);

  metrics = metrics.map(normalizeFormatAndCurrency);

  if (previousMetrics)
    metrics = attachPrevPeriod(
      metrics,
      previousMetrics.map(mapIds),
      currency,
      trendsPeriods
    );
  metrics = collapseAndAttachWithTaxMetric(metrics, taxPairs);
  return metrics;
}

export function collapseKpiToCurrency(kpiValue, currency) {
  const ret = {
    ...kpiValue,
  };

  if (kpiValue.format.startsWith('money')) {
    ret.currency = currency;

    ret.currentAmount = kpiValue.currentAmount?.find(
      e => e.currency === currency
    ).amount;

    ret.prevYearAmount = kpiValue.prevYearAmount?.find(
      e => e.currency === currency
    ).amount;

    ret.targetAmount = kpiValue.targetAmount?.find(
      e => e.currency === currency
    ).amount;
  }

  return ret;
}

export const collapseToCurrency = (metric, currency) => ({
  ...metric,
  appValue: collapseKpiToCurrency(metric.kpiValue, currency),
});

export function attachDpy(metrics) {
  return metrics.map(m => {
    let dpy;
    if (
      !isNullOrUndefined(m.appValue.currentAmount) &&
      !isNullOrUndefined(m.appValue.prevYearAmount)
    ) {
      const currentAmount = m.appValue.currentYearCompAmount
        ? m.appValue.currentYearCompAmount
        : m.appValue.currentAmount;
      const prevYearAmount = m.appValue.prevYearCompAmount
        ? m.appValue.prevYearCompAmount
        : m.appValue.prevYearAmount;
      dpy = computeDpy(
        currentAmount,
        prevYearAmount,
        m.appValue.format,
        m.appValue.compare
      );
    }
    return {
      ...m,
      dpy,
    };
  });
}

/** Delta Previous Plan */
export function attachDpp(metrics) {
  return metrics.map(m => {
    let dpp;
    if (
      !isNullOrUndefined(m.appValue.currentAmount) &&
      !isNullOrUndefined(m.appValue.targetAmount)
    ) {
      dpp = computeDpy(
        m.appValue.currentAmount,
        m.appValue.targetAmount,
        m.appValue.format,
        m.appValue.compare
      );
    }
    return {
      ...m,
      dpp,
    };
  });
}

export const formatValues = (metric, { maximumFractionDigits } = {}) => {
  const { appValue, ...rest } = metric;
  const {
    currentAmount,
    currentLLYAmount,
    format,
    currency,
    targetAmount,
    targetLLYAmount,
  } = appValue;

  let formattedValue = withTraslation('no_data');
  let formattedLLYValue = null;
  let formattedTargetValue = null;
  let formattedLLYTargetValue = null;

  if (!isNullOrUndefined(currentAmount)) {
    formattedValue = formatKpi(currentAmount, {
      format,
      currency,
      maximumFractionDigits,
    });
  }
  if (!isNullOrUndefined(currentLLYAmount)) {
    formattedLLYValue = formatKpi(currentLLYAmount, {
      format,
      currency,
      maximumFractionDigits,
    });
  }
  if (!isNullOrUndefined(targetAmount)) {
    formattedTargetValue = formatKpi(targetAmount, {
      format,
      currency,
      maximumFractionDigits,
    });
  }
  if (!isNullOrUndefined(targetLLYAmount)) {
    formattedLLYTargetValue = formatKpi(targetLLYAmount, {
      format,
      currency,
      maximumFractionDigits,
    });
  }
  return {
    ...rest,
    appValue,
    value: currentAmount,
    value_lly: currentLLYAmount,
    targetAmount,
    formattedValue,
    formattedLLYValue,
    formattedTargetValue,
    formattedLLYTargetValue,
  };
};

export function replaceWithTaxMetricIfNeeded(metrics, includeTaxes) {
  if (!includeTaxes) return metrics;

  return metrics.map(m => {
    if (!m.taxMetric) return m;

    return {
      ...m.taxMetric,
      noTaxId: m.id,
      promoted: m.promoted,
    };
  });
}

export const selectDisplayFields = ({
  id,
  promoted,
  order,
  category,
  description,
  label,
  dpy,
  dpp,
  value,
  value_lly,
  formattedValue,
  formattedTargetValue,
  appValue,
  noTaxId,
  realtime,
  formattedLLYValue,
  formattedLLYTargetValue,
  targetAmount,
  formattedTrends,
  hasTrends,
}) => ({
  id,
  order,
  category,
  description,
  label,
  dpy,
  dpp,
  value,
  value_lly,
  formattedValue,
  formattedLLYValue,
  formattedTargetValue,
  formattedLLYTargetValue,
  compare: appValue.compare,
  format: appValue.format,
  noTaxId,
  realtime,
  targetAmount,
  formattedTrends,
  hasTrends,
  ...(!!promoted && { promoted }),
});

export function transformMetrics({
  metricsResponse,
  kpiMetaResponse,
  useUsdAmount,
  includeTaxes,
  useLLY,
  periodId,
  realtimePage = false,
  trendsPeriods,
}) {
  const currency = useUsdAmount
    ? 'USD'
    : selectCurrencyFromMetricsResponse(metricsResponse);
  return transformMetrics_v2({
    metricsResponse,
    kpiMetaResponse,
    currency,
    includeTaxes,
    useLLY,
    periodId,
    realtimePage,
    trendsPeriods,
  });
}

export function attachAndFormatTimeStamp(
  result,
  timeStamp,
  realtimePage = false
) {
  const stampDate = new Date(timeStamp);
  const now = new Date();

  // If generation happened yesterday (before midnight) it means prior day is yesterday and it's not yet generated,
  // although it's less than 24h earlier.
  // Prior day (yesterday) will be generated today at some point.
  // Attaching stale data for the other periods for consistency.
  let stale = false;
  if (realtimePage) {
    stale = stampDate.getTime() < now.getTime();
  } else {
    stale =
      stampDate.getTime() <
      new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
  }
  if (stale) {
    result.formattedTimeStamp = realtimePage
      ? formatTimeStamp1(timeStamp)
      : formatTimeStamp(timeStamp);
  }
  return result;
}

export function transformMetrics_v2({
  metricsResponse,
  kpiMetaResponse,
  currency,
  includeTaxes,
  useLLY,
  periodId,
  realtimePage = false,
  trendsPeriods,
}) {
  const { metrics, isTherePrevPeriod, timeStamp } =
    standardizeMetricsResponse_v2(
      metricsResponse,
      kpiMetaResponse,
      useLLY,
      periodId,
      currency,
      trendsPeriods
    );
  let result = metrics;
  result = replaceWithTaxMetricIfNeeded(result, includeTaxes);
  result = result.map(x => collapseToCurrency(x, currency));

  if (!realtimePage) {
    if (isTherePrevPeriod) result = attachDpy(result);
    // TODO investigate real data source for previous plan
    result = attachDpp(result);
  }
  result = result.map(formatValues);
  result = result.map(selectDisplayFields);
  attachAndFormatTimeStamp(result, timeStamp, realtimePage);

  return result;
}

export function transformTrends(metricA, metricB) {
  const trendsData = {};
  metricB &&
    Object.keys(metricB).forEach(key => {
      let metricData;
      metricData = metricB[key].map(renameProp('value', 'value_cmp'));
      metricData = metricData.map(
        renameProp('formattedValue', 'formattedCmpValue')
      );
      trendsData[key] = metricData;
    });
  const trends = {};
  metricA &&
    Object.keys(metricA).forEach(key => {
      trends[key] = metricA[key].map((e, i) => {
        //trendsData[key][i].value_cmp = trendsData[key][i].value_cmp * 2;
        trendsData[key][i].compare = true;
        return { ...e, ...trendsData[key][i] };
      });
    });
  return trends;
}

export function transformTrendswithPY(
  metricA,
  metricB,
  format,
  currency,
  trendsPeriods
) {
  const trendsData = {};
  const trendsB = {};
  metricB &&
    Object.keys(metricB).forEach(key => {
      let metricData;
      metricData = metricB[key]
        ?.map(renameProp('value', 'value_py'))
        .map(renameProp('usdValue', 'usdValue_py'));
      const metrics = metricData.map(m => {
        const currencyCodePY =
          currency === 'USD'
            ? currency
            : m.currency_code
            ? m.currency_code
            : 'USD';
        const value_py =
          currency === 'USD' && format === 'money' ? m.usdValue_py : m.value_py;
        const formattedPyValue = value_py
          ? formatKpi(value_py, {
              format: format,
              currency: currencyCodePY,
              maximumFractionDigits: null,
            })
          : 0;

        return {
          ...m,
          formattedPyValue,
        };
      });
      trendsB[key] = metrics;
    });

  const trendsA = {};
  metricA &&
    Object.keys(metricA).forEach(key => {
      trendsA[key] = metricA[key]?.map((e, i) => {
        let value;
        value =
          currency === 'USD' && format === 'money'
            ? metricA[key][i].usdValue
            : metricA[key][i].value;
        const currencyCode =
          currency === 'USD'
            ? currency
            : metricA[key][i]?.currency_code
            ? metricA[key][i]?.currency_code
            : 'USD';
        let formattedValue;
        formattedValue = value
          ? formatKpi(value, {
              format: format,
              currency: currencyCode,
              maximumFractionDigits: null,
            })
          : 0;
        return { ...e, value, formattedValue };
      });
    });
  trendsPeriods &&
    Object.keys(trendsPeriods).forEach(key => {
      const trendsObj = trendsPeriods[key]?.map((e, i) => {
        const metricsA = trendsA
          ? trendsA[key]?.find(x => x.period_name === e)
          : {};
        const metricsB = trendsB
          ? trendsB[key]?.find(x => x.period_name === e)
          : {};
        return { ...metricsA, ...metricsB, period_name: e };
      });
      trendsData[key] = trendsObj;
    });
  return trendsData;
}

export const transformMetricsInPanels = kpiList => {
  const groupList = {};
  const result = [];

  for (const kpiListItem of kpiList) {
    if (groupList[kpiListItem.category] === undefined)
      groupList[kpiListItem.category] = [];
    groupList[kpiListItem.category].push(kpiListItem);
  }

  Object.keys(groupList).forEach(groupListItem => {
    try {
      result.push({
        panelName: convertTextInTranslationKey(groupListItem),
        sections: groupList[groupListItem],
      });
    } catch (e) {
      console.error(`Failed to create panel for ${groupListItem}. Data =`, e);
    }
  });

  return result;
};

export const transformProductsInPanels = products => {
  const result = products.map(product => {
    const items = [];
    Object.keys(product.items).forEach(productCategory => {
      try {
        items.push({
          panelName: productCategory,
          sections: product.items[productCategory],
        });
      } catch (e) {
        console.error(
          `Failed to create panel for ${productCategory}. Data =`,
          e
        );
      }
    });

    return {
      ...product,
      items,
    };
  });
  return result;
};

export const convertTextInTranslationKey = label =>
  `${label.trim().replace(/ /g, '_')}`.toLowerCase();
