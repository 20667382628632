import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import LineChart from '../Charts/recharts/LineChart';
import { ButtonTabs, ButtonTab } from '../../components/ButtonTabs';
import { withTraslation } from '../../data/LanguageProvider';

const styles = theme => ({
  root: {
    //height: 300,
    width: '100%',
    heigth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& .recharts-responsive-container': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiTab-root': {
      color: '#000',
      boxShadow: 'none',
      textTransform: 'inherit',
      fontSize: '1.2rem',
      minWidth: '72px',
    },
    '& .Mui-selected': {
      backgroundColor: '#d1cccc',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      // justifyContent: 'space-between'
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  },
});

const KPiGraphTabs = ({
  classes,
  data,
  dots,
  dataKeyA,
  dataKeyB,
  strokeA,
  strokeB,
  useLLY,
  format,
  useUsdAmount,
}) => {
  const [value, setValue] = useState('fiscal_week');
  const chartData = data?.[value];
  const graphTabs = [
    { index: 'fiscal_week', label: withTraslation('week') },
    { index: 'fiscal_month', label: withTraslation('month') },
    { index: 'fiscal_quarter', label: withTraslation('quarter') },
    { index: 'fiscal_year', label: withTraslation('year') },
  ];
  const parentRef = useRef(null);

  const [parentSize, setParentSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set parent width for Graph
      setParentSize({
        width: parentRef.current.offsetWidth,
        height: parentRef.current.offsetHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div className={classes.root} ref={parentRef}>
      <LineChart
        data={chartData}
        dots={dots}
        dataKeyA={dataKeyA}
        dataKeyB={dataKeyB}
        strokeA={strokeA}
        strokeB={strokeB}
        width={parentSize.width}
        useLLY={useLLY}
        format={format}
        useUsdAmount={useUsdAmount}
        dataPeriod={value}
      />
      <ButtonTabs
        onChange={(_, val) => {
          setValue(val);
        }}
        value={value}
        className={classes.buttonTabcontainer}
      >
        {graphTabs.map(({ index, label }) => (
          <ButtonTab
            className={classes.buttonTab}
            key={index}
            label={label}
            value={index}
          />
        ))}
      </ButtonTabs>
    </div>
  );
};
export default withStyles(styles, { name: 'LriGraphTabs' })(KPiGraphTabs);
