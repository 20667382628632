import cx from 'clsx';
import React from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from '@material-ui/core';
import { KPIsIcon, ProductsIcon, CompareIcon } from './Icons';
import { withTraslation } from '../data/LanguageProvider';

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      zIndex: theme.zIndex.appBar,
      height: 'calc(56px + env(safe-area-inset-bottom))',
      paddingLeft: 'env(safe-area-inset-left)',
      paddingRight: 'env(safe-area-inset-right)',
    },
    ghostBottomNavigation: {
      paddingBottom: 'calc(56px + env(safe-area-inset-bottom))',
    },
    tabRoot: {
      paddingBottom: 'calc(8px + env(safe-area-inset-bottom))',
    },
    tabSelected: {},
    tabLabel: {
      '&.Mui-selected': {
        fontSize: '1.2rem',
      },
    },
  }),
  { name: 'LriBottomBar' }
);

const navItems = [
  // { label: 'Today', value: '', icon: <ClockIcon fontSize="large" /> },
  { label: 'KPIs', value: 'kpis', icon: <KPIsIcon fontSize="large" /> },
  {
    label: 'Products',
    value: 'products',
    icon: <ProductsIcon fontSize="large" />,
  },
  {
    label: 'Compare',
    value: 'compare',
    icon: <CompareIcon fontSize="large" />,
  },
];

const BottomBar = ({ className, showLabels = true, value, ...otherProps }) => {
  const styles = useStyles();

  return (
    <>
      <BottomNavigation
        className={cx(className, styles.root)}
        showLabels={showLabels}
        value={value}
        {...otherProps}
      >
        {navItems.map(({ label, value, icon }) => (
          <BottomNavigationAction
            classes={{
              root: styles.tabRoot,
              selected: styles.tabSelected,
              label: styles.tabLabel,
            }}
            value={value}
            key={value}
            label={withTraslation(value)}
            icon={icon}
            color="secondary"
          />
        ))}
      </BottomNavigation>
      <div className={styles.ghostBottomNavigation} />
    </>
  );
};

BottomBar.propTypes = {
  ...BottomNavigation.propTypes,
};

export default BottomBar;
