import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: {
    width: '100%',
    paddingTop: '10px',
    '& >label': {
      fontSize: '13px',
      color: '#fff',
    },
  },
  selectInput: {
    width: '100%',
    color: '#000000',
    backgroundColor: '#ffffff',
    marginTop: '10px',
    paddingLeft: '6px',
  },
});

const MainMenuSelectOption = ({ classes, selected, onSelectChange }) => {
  const handleSelectLanguage = e => {
    e.preventDefault();
    onSelectChange(e.target.value);
  };
  return (
    <div className={classes.root}>
      <InputLabel id="demo-simple-select-filled-label">Language</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={selected}
        onChange={handleSelectLanguage}
        className={classes.selectInput}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ja">Japanese</MenuItem>
        <MenuItem value="ms">Malay</MenuItem>
        {/* <MenuItem value="zh-TW">Chinese(T)</MenuItem> */}
        <MenuItem value="zh-CN">Chinese(S)</MenuItem>
      </Select>
    </div>
  );
};

MainMenuSelectOption.propTypes = {
  selected: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func,
};

export default withStyles(styles, { name: 'LriMainMenuSelectOption' })(
  MainMenuSelectOption
);
