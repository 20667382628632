import React from 'react';
import PropTypes from 'prop-types';
import {
  Card as MuiCard,
  CardActionArea,
  withStyles,
  withTheme,
} from '@material-ui/core';

import * as CustomPropTypes from '../../propTypes';

const propsToColor = theme => ({ color = 'default' }) => {
  if (color === 'inherit') return color;
  if (color === 'default') return theme.palette.text.primary;
  return theme.palette[color].contrastText;
};

const propsToBgColor = theme => ({ color = 'default' }) => {
  if (color === 'inherit') return color;
  if (color === 'default') return theme.palette.background.panel;
  return theme.palette[color].main;
};

const propsToJustifyContent = () => ({ justify = 'center' }) => {
  return justify;
};

const propsToMarginTop = theme => ({ spacing = 1.5 }) => {
  return theme.spacing(spacing);
};

const styles = theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: propsToJustifyContent(),
    flexDirection: 'column',
    background: propsToBgColor(theme),
    position: 'relative',
    width: '100%',
    borderRadius: theme.spacing(1),
    color: propsToColor(theme),
    marginTop: propsToMarginTop(theme),
  },
  actionArea: {
    borderRadius: theme.spacing(1),
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
});

const Card = ({ classes, children, onClick, color }) => (
  <MuiCard elevation={0} className={classes.root}>
    {children}
    {onClick && (
      <CardActionArea onClick={onClick} className={classes.actionArea} />
    )}
  </MuiCard>
);

Card.propTypes = {
  color: CustomPropTypes.themePaletteColor,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default withTheme(withStyles(styles, { name: 'LriCard' })(Card));
