import { applyAll } from '../../util/function';
import useStockData from './useStockData';
import { useNoPerformance, usePerformanceData } from './usePerformanceData';

const useProductsData = (...args) => {
  let combined;
  if (args[2].startsWith('yr') || args[2].startsWith('qtr')) {
    combined = applyAll(useStockData, useNoPerformance);
  } else {
    combined = applyAll(useStockData, usePerformanceData);
  }
  return combined(...args);
};

const useFullProductsData = (
  locationId,
  locationParams,
  periodId,
  currency,
  includeTaxes,
  useLLY
) => {
  const [asyncStock, asyncPerformance] = useProductsData(
    locationId,
    locationParams,
    periodId,
    currency,
    includeTaxes,
    useLLY
  );

  const error = asyncStock.error;
  const loading = asyncStock?.loading || asyncPerformance?.loading;

  let data;
  if (!loading && !error) {
    data = {
      stock: asyncStock?.data,
      products: asyncPerformance?.data,
      formattedTimeStamp:
        asyncStock?.data?.formattedTimeStamp ??
        asyncPerformance?.data?.formattedTimeStamp,
    };
  }

  return {
    loading,
    data,
    error,
    refetch: () => {
      asyncStock.refetch();
      asyncPerformance.refetch();
    },
  };
};

export default useFullProductsData;
