import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Select, SelectDrawer } from '../../components/Select';
import { noop } from '../../util/function';
import { usePreferencesManager } from '../user-preferences';
import usePeriodData from '../hooks/usePeriodData';
import { subMilliseconds, subDays, subMonths, subYears } from 'date-fns';
import LoadingBar from '../../components/LoadingBar';
import { useRetryPeriod } from '../hooks/useRetryPeriod';
import { withTraslation } from '../../data/LanguageProvider';

const fixedPeriodOptions = [
  { id: 'pd', label: 'Prior Day' },
  { id: 'wtd', label: 'Week to Date' },
  { id: 'mtd', label: 'Month to Date' },
  { id: 'qtd', label: 'Quarter to Date' },
  { id: 'ytd', label: 'Year to Date' },
];

const periodKeys = {
  pd: 'day',
  wtd: 'week',
  mtd: 'month',
  qtd: 'quarter',
  ytd: 'year',
};

const getInitialPeriod = (items, pm) => {
  if (!items) return null;
  return items.find(x => x.id === pm.get('period')) || items[0];
};

const convertTimeStamp = (date = new Date()) => {
  const utc =
    new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000;
  return utc;
};

const findPeriod = (periodList, date) => {
  // API data is inclusive lower bound, exclusive upper bound.
  // TODO: binary search
  const test =
    periodList.find(period => {
      return (
        convertTimeStamp(period.startDate) <= convertTimeStamp(date) &&
        convertTimeStamp(period.endDate) > convertTimeStamp(date)
      );
    }) || {};
  return test;
};

const PeriodsShell = ({ children, onClose = noop }) => {
  const pm = usePreferencesManager();

  const { data: periods, loading, error } = usePeriodData();

  const [drawer, setDrawer] = useState(false);
  const [selected, setSelected] = useState(() =>
    getInitialPeriod(fixedPeriodOptions, pm)
  );

  const toggleSelect = () => {
    setDrawer(true);
  };

  const { retryPeriod, setRetryPeriod } = useRetryPeriod();
  const handleSelect = item => {
    setSelected(item);
    setRetryPeriod(false);
    pm.set('period', item.id);
    onClose();
    setDrawer(false);
  };

  const getSelectedPeriod = () => {
    const key = periodKeys[selected.id];
    const subAmount = key => {
      switch (key) {
        case 'day':
          return subDays(new Date(), 2);
        case 'week':
          return subDays(new Date(), 7);
        case 'month':
          return subMonths(new Date(), 1);
        case 'quarter':
          return subMonths(new Date(), 3);
        case 'year':
          return subYears(new Date(), 1);
        default:
          return subDays(new Date(), 1);
      }
    };
    // TODO: Timezone handling.
    const date = retryPeriod ? subAmount(key) : subDays(new Date(), 1);
    const period = findPeriod(periods[key], date);
    // TODO: Proper handling when there are no matching periods.
    const eDate = subMilliseconds(period.endDate, 1000);
    period.currentDate = retryPeriod ? eDate : period.currentDate;
    return period;
  };

  const getTodaysPeriod = () => {
    const date = new Date();
    const period = findPeriod(periods.day, date);
    return period;
  };

  let err = error;

  return (
    <>
      {err && children(null, null, () => null, err)}
      {loading && <LoadingBar />}
      {!err && periods && (
        <>
          {children(selected, toggleSelect, getSelectedPeriod, getTodaysPeriod)}
          <SelectDrawer
            open={drawer}
            title={withTraslation('select_period')}
            onClose={() => {
              onClose();
              setDrawer(false);
            }}
          >
            <Select
              items={fixedPeriodOptions}
              selected={selected}
              onSelect={handleSelect}
            />
          </SelectDrawer>
        </>
      )}
    </>
  );
};

PeriodsShell.propTypes = {
  onClose: PropTypes.func,
};

export default PeriodsShell;
